import JustValidate from 'just-validate/dist/js/just-validate.min';
import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './ReCaptchaActions';
import HystModal from 'hystmodal';

export const initContactForm = () => {
    const formSelector = '[data-contact-us-form]';
    const form = document.querySelector(`${formSelector}`);
    if (!form) return;
    const modal = new HystModal();

    const formAction = new window.JustValidate(`${formSelector}`, {
        rules: {
            email: {
                required: true,
                email: true,
                strength: {
                    custom: '^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,4}$',
                },
            },
            subject: {
                required: true,
            },
            text: {
                required: true,
            },
        },

        messages: {
            email: 'Field filled incorrectly',
        },

        submitHandler: function (form, values, ajax) {
            if (isReCaptchaValid()) {
                removeRecaptchaError(`${formSelector}`);
            } else {
                setRecaptchaError(`${formSelector}`);
                return;
            }

            ajax({
                url: form.getAttribute('action'),
                method: 'POST',
                data: values,
                async: true,
                callback: function (response) {
                    resetForm(form);
                    modal.open('[data-modal="successfully-sent"]');
                },
                error: function (response) {
                    console.log(response);
                    modal.open('[data-modal="error-message"]');
                },
            });
        },

        invalidFormCallback: function (errors) {
            if (errors && !isReCaptchaValid()) {
                setRecaptchaError(`${formSelector}`);
            }
        },
    });

    function resetForm(form) {
        grecaptcha.reset();
        form.reset();
        removeRecaptchaError(`${formSelector}`);
    }
};
