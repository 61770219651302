import anime from 'animejs';

export const initMobileMenu = () => {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    const body = document.querySelector('[data-body]');
    const sublistMenuToggle = document.querySelectorAll('[data-sublist-menu-toggle]');
    const activeClass = 'menu-opened';
    const activeSublistClass = 'is-open-sublist';
    const duration = 300;
    const point = 680;

    // LISTENERS
    hamburger?.addEventListener('click', handleOnClickHamburger, false);

    sublistMenuToggle.forEach((toggle) => {
        toggle.addEventListener('click', handleOnClickToggle, false);
    });

    document.addEventListener('keyup', function () {
        if (document.activeElement.closest('[data-sublist-menu-wrap]')) {
            const dropDown = document.activeElement.closest('[data-sublist-menu-wrap]');
            dropDown.classList.add(activeSublistClass);
        } else {
            const dropDown = document.querySelector(`.${activeSublistClass}[data-sublist-menu-wrap]`);
            dropDown?.classList.remove(activeSublistClass);
        }
    });

    window.addEventListener('resize', function () {
        const isMenuOpened = document.body.classList.contains('menu-opened');

        if (window.innerWidth < point && isMenuOpened) {
            const wraps = document.querySelectorAll('[data-sublist-menu-wrap]');

            wraps.forEach((wrap) => {
                if (wrap.classList.contains(activeSublistClass)) {
                    const sublistMenu = wrap.querySelector('[data-sublist-menu]');
                    sublistMenu.style.height = '';
                }
            });

            closeAllSublistMenus();
            disableScroll();
        } else if (isMenuOpened) {
            const wraps = document.querySelectorAll('[data-sublist-menu-wrap]');

            wraps.forEach((wrap) => {
                if (wrap.classList.contains(activeSublistClass)) {
                    const sublistMenu = wrap.querySelector('[data-sublist-menu]');
                    sublistMenu.style.height = '';
                }
            });

            enableScroll();
            closeAllSublistMenus();
            document.body.classList.remove('menu-opened');
        }
    });

    document.addEventListener('click', function (event) {
        if (!event.target.closest('[data-sublist-menu-wrap]')) {
            closeAllSublistMenus();
        }
    });

    // HANDLERS
    function handleOnClickHamburger() {
        body.classList.toggle(activeClass);
        body.classList.contains(activeClass) ? disableScroll() : enableScroll();
    }

    function handleOnClickToggle() {
        const wrap = this.closest('[data-sublist-menu-wrap]');
        const sublistMenu = wrap.querySelector('[data-sublist-menu]');

        if (window.innerWidth < point) {
            if (wrap.classList.contains(activeSublistClass)) {
                closeMobileSublistMenu(sublistMenu, wrap);
            } else {
                openMobileSublistMenu(sublistMenu, wrap);
            }
        } else {
            if (wrap.classList.contains(activeSublistClass)) {
                wrap.classList.remove(activeSublistClass);
                sublistMenu.style.height = '';
            } else {
                closeAllSublistMenus();
                wrap.classList.add(activeSublistClass);
            }
        }
    }

    function openMobileSublistMenu(sublistMenu, wrap) {
        const height = sublistMenu.scrollHeight;
        wrap.classList.add(activeSublistClass);

        anime({
            targets: sublistMenu,
            height: [0, height],
            easing: 'linear',
            duration: duration,
            complete: function () {
                sublistMenu.style.height = 'auto';
            },
        });
    }

    function closeMobileSublistMenu(sublistMenu, wrap) {
        const height = sublistMenu.scrollHeight;
        sublistMenu.style.height = `${height}px`;
        wrap.classList.remove(activeSublistClass);

        anime({
            targets: sublistMenu,
            height: 0,
            easing: 'linear',
            duration: duration,
            complete: function () {
                sublistMenu.style.height = '';
            },
        });
    }
};

export function closeAllSublistMenus() {
    const wraps = document.querySelectorAll('[data-sublist-menu-wrap]');
    const activeSublistClass = 'is-open-sublist';
    const sublistMenu = document.querySelector('[data-sublist-menu]');

    wraps.forEach((wrap) => {
        wrap.classList.remove(activeSublistClass);
        sublistMenu.style.height = '';
    });
}
