import { insertScript } from '../../../../../Common/Resources/src_front/js/helper-functions/insertScript';
import { setCookie } from './helper-function/coockie/setCookie';

export function initJivosite() {
    if (!window.geofinderAIJivoChatHash) return;

    setTimeout(function () {
        insertScript(`//code.jivosite.com/widget/${window.geofinderAIJivoChatHash}`);

        setInterval(function () {
            if (Boolean(window.jivo_api)) {
                window.jivo_onMessageSent = function jivo_onMessageSent() {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatHash', window.geofinderAIJivoChatHash, cookieOptions);
                };

                window.jivo_api.getVisitorNumber(function (error, visitorNumber) {
                    const cookieOptions = {
                        path: '/',
                    };
                    setCookie('jivoChatVisitor', visitorNumber, cookieOptions);
                });
            }
        }, 1000);
    }, 5000);
}
