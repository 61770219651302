import { initCreateAccountEmail } from './forms/initCreateAccountEmail';
import { initContactForm } from './forms/initContactForm';
import { initAppendHiddenInputsToPlanForms } from '../../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';
import { initEmailAutocompleteDropdown } from '../../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';

export const initForms = () => {
    initEmailAutocompleteDropdown();
    initCreateAccountEmail();
    initContactForm();
    initAppendHiddenInputsToPlanForms();
};
