import { getCookie } from '../../../../../../Common/Resources/src_front/js/helper-functions/getCookie';
import { setCookie } from '../../../../../../Common/Resources/src_front/js/helper-functions/setCookie';

export const initCookieBanner = () => {
    // VARS
    const cookieBanner = document.querySelector('[data-cookie-banner]');
    if (!cookieBanner) return;
    const cookieBtn = cookieBanner.querySelectorAll('[data-cookie-apply]');

    // INITS & LISTENERS
    if (!getCookie('cookie-policy')) {
        showCookieBanner();
        [...cookieBtn].forEach((btn) => {
            btn.addEventListener('click', handleOnCloseCookieBanner, false);
        });
    }

    // HANDLERS
    function handleOnCloseCookieBanner() {
        if (this.dataset.cookieApply === 'true') {
            setCookie('cookie-policy', 'apply');
        }
        hideCookieBanner();
    }

    // FUNCTIONS
    function showCookieBanner() {
        cookieBanner.classList.remove('hidden');
    }

    function hideCookieBanner() {
        cookieBanner.classList.add('hidden');
    }
};
