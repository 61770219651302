import { closeAllSublistMenus } from './initMobileMenu';

export const initReloadFunnelState = () => {
    hidePopupAnimation();
    stopAnimationButton();
    window.pageLoader.hide();
    closeAllSublistMenus();
};

function hidePopupAnimation() {
    const popup = document.querySelector('[data-popup-location]');
    if (!popup) return;

    popup.classList.remove('is-opened');
    enableScroll();
}

function stopAnimationButton() {
    const locationForms = document.querySelectorAll('[data-phone-number-form]');
    if (!locationForms.length) return;

    [...locationForms].forEach((form) => {
        const button = form.querySelector('[data-phone-number-form-submit]');
        button?.classList.remove('active-spinner');
        form.removeAttribute('disabled');
        form.classList.remove('error');
        form.classList.remove('disabled');
    });
}
